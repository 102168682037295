import React from 'react'
import { Link } from 'gatsby'
import fibooMono from '../images/fiboo_mono.svg'
import fibooShapeMono from '../images/fiboo_icon_shape.svg'
import fiboo_shape_bg from "../images/fiboo_colored.svg"
import fiboo_bw from "../images/fiboo_bw.svg"

const Footer = ({ isAnimated, light, whiteLogo }) => {
  const getLogo = () => {
    if (whiteLogo) {
      return fiboo_bw;
    }
    else if(light == true)
    {
      return fibooMono;
    }
    else{
      return fiboo_shape_bg;
    }
  }

  return (
    <footer className={`footer  ${light ? '' : 'dark'}`}>
      <div id="inview5" className="footer-container">
        <div className="footer-block _0">
          <a href="/tr" aria-current="page" className="w-inline-block w--current"><img src={getLogo()} loading="lazy" alt="" className="image-5" /></a>
          <h1 className="heading-9">Sanayi, İstanbul Teknoloji Geliştirme Bölgesi, Teknopark Blv No:1, 34906 Pendik/İstanbul<br /> <br /><br /><span className="text-span-6">®</span>2020 Fiboo</h1>
        </div>
        <div className="footer-block _1">
          <div className="footer-header">Ürünlerimiz</div>
          <a href="http://moodly.io" className="footer-link">Moodly</a>
        </div>

        <div className="footer-block">
          <div className="footer-header">Sosyal Medya</div>
          <a href="https://www.linkedin.com/company/fibootech/" className="footer-link">Linkedin</a>
          <a href="https://medium.com/fiboo" className="footer-link">Blog</a>
          <a href="https://twitter.com/fibootech" className="footer-link">Twitter</a>
          <a href="https://www.instagram.com/fibootech/" className="footer-link">Instragram</a>
          <a href="https://www.facebook.com/fibootech" className="footer-link">Facebook</a>
        </div>
      </div>
      <img src={fibooShapeMono} loading="lazy" data-w-id="24539535-ebd2-4e50-b78f-63dcc18d71f9" alt="" className="footer-symbol" />
    </footer>
  );
}

export default Footer;