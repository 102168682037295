import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import FibooLogo from './icons/fiboo';

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;

const HeaderMenuItem = ({ path, title, currentPath, isButton }) => (
  <Link to={path} className={`navbar-link right w-inline-block ${path === currentPath ? 'active' : ''} ${isButton ? 'button' : ''}`}>
    <div>{title}</div>
  </Link>
)

const HeaderMenu = ({ children }) => {
  return (
    <div className="navbar-center">
      {children}
    </div>
  )
}

const Header = ({ isAnimated, currentPath, light }) => {
  const [showMenu, setShowMenu] = useState(false);

  const onClickBurgerMenu = () => {
    const player = document.querySelector("#headerLottie");
    var isShowMenu = !showMenu;


    if (isShowMenu) {
      player.setDirection(1);
      player.play();
      $(".navbar-mobile-overlay").css("opacity", "1").css("display", "block");
    }
    else {
      player.setDirection(-1);
      player.play();

      setTimeout(() => {
        $(".navbar-mobile-overlay").css("opacity", "0").css("display", "none");
      }, 200);
    }

    setShowMenu(isShowMenu);


  }

  useEffect(() => {
    // Fade Up 5 (Product Preview)
    var fadeUp6 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp6
      .add({
        targets: ['.navbar-m-link'],
        translateY: [25, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 50 + 80 * i
      });



    // Play animation when scrolled into view
    $('.m-main').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp6.play();
        fadeUp6.restart();
      } else {
      }
    });
  }, []);

  const getLottieSource = ()=>{
    if (light) {
      return "https://assets10.lottiefiles.com/packages/lf20_6on4bs6k.json";
    } else {
      return "https://assets2.lottiefiles.com/packages/lf20_qjzgb2po.json";
    }
  }

  return (
    <header className={`header ${isAnimated ? 'slide-down' : ''} ${light ? '' : 'dark'}`} id="topHeader">
      <nav className="navbar">
        <div className="navbar-desktop">
          <Link to="/" className="navbar-logo w-inline-block w--current">
            <FibooLogo light={light} loading="lazy" />
          </Link>

          <HeaderMenu>
            <HeaderMenuItem {...{
              path: '/hakkimizda/',
              title: 'HAKKIMIZDA',
              currentPath: currentPath
            }} />

            {/* <HeaderMenuItem {...{
            path: '/bizden-haberler/',
            title: 'BİZDEN HABERLER',
            currentPath: currentPath
          }} /> */}

            {/* <HeaderMenuItem {...{
            path: '/urunler/',
            title: 'ÜRÜNLER',
            currentPath: currentPath
          }} /> */}

            <HeaderMenuItem {...{
              path: '/hizmetler/',
              title: 'HİZMETLER',
              currentPath: currentPath
            }} />

            <HeaderMenuItem {...{
              path: '/referanslar/',
              title: 'REFERANSLAR',
              currentPath: currentPath
            }} />

            <HeaderMenuItem {...{
              path: '/iletisim/',
              title: 'İLETİŞİM',
              currentPath: currentPath
            }} />
          </HeaderMenu>

          <Link to="/en/" className="navbar-link language-container w-inline-block">
            {/* <div className="text-block">EN</div> */}
          </Link>
          <div className="burger-menu" onClick={onClickBurgerMenu}>
            <lottie-player
              id="headerLottie"
              // autoplay
              // loop
              speed="3"
              mode="normal"
              src={getLottieSource()}
            ></lottie-player>
          </div>

        </div>
        <div class="navbar-mobile-overlay">
          <div class="m-main">
            <a href="/hakkimizda/"
              class="navbar-m-link w-inline-block">
              <div class="navbar-m-txt">HAKKIMIZDA</div>
            </a>
            <a href="/hizmetler/" class="navbar-m-link w-inline-block">
              <div class="navbar-m-txt">HİZMETLER</div>
            </a>
            <a href="/referanslar/" class="navbar-m-link w-inline-block">
              <div class="navbar-m-txt">REFERANSLAR</div>
            </a>
            <a href="/iletisim/" class="navbar-m-link w-inline-block">
              <div class="navbar-m-txt">İLETİŞİM</div>
            </a>
          </div>
          <div class="no-scoll-imbed w-embed"></div>
        </div>
      </nav>

    </header>
  )
};

export default Header
