import React from "react"

import fiboo_shape_bg from "../../images/fiboo_colored.svg"
import fiboo_bw from "../../images/fiboo_bw.svg"

export default ({ light }) => {
  
  if(light){
    return ( <img src={fiboo_shape_bg} /> );
    
  } else {
    return ( <img src={fiboo_bw} /> );
  }
}