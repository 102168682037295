/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import CookieConsent from "react-cookie-consent";

import Header from "./header.tr"
import Footer from './footer.tr'
import { Link } from 'gatsby'

import "./layout.css"
import "../style/style.scss"
import $ from "jquery";


export default class Layout extends React.Component {
  componentDidMount() {
    const { bodyClassName } = this.props;
    if (bodyClassName) {
      $("body").addClass(bodyClassName);
    } else {
      $("body").removeClass();
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset) {
        document.body.classList.add('scrolled');
      } else {
        document.body.classList.remove('scrolled');
      }
    });
  }

  render() {
    const { children, isAnimated, currentPath, headerLight, fullPage, bodyClassName, footerLight, footerWhiteLogo } = this.props;
    return (
      <>
        <div className={`content ${fullPage ? 'fullPage' : ''}`}>
          <Header isAnimated={isAnimated} currentPath={currentPath} light={headerLight} />
          <main className="main">{children}</main>
          <Footer isAnimated={isAnimated} light={footerLight} whiteLogo={footerWhiteLogo} />

          {
            fullPage ?
              <a className='scrollButton' href="#topHeader">
                <div className="m">
                  <div className="s"></div>
                </div>
              </a>
              : null
          }

          <CookieConsent
            location="bottom"
            buttonText="X"
            cookieName="fiboo_consent"
            style={{ alignItems: "center", backgroundColor: "black", fontSize: "12px" }}
            contentStyle={{ flex: "1" }}
            buttonStyle={{ color: "white", borderRadius: "50%", backgroundColor: "#0064C8" }}
          >
            Sitemizde çerez konumlandırmaktayız, kullanmaya devam ettiğinizde çerezler ile toplanan kişisel verileriniz <Link to="/guvenlik-politikamiz/" className="white-link">Güvenlik Politikamız</Link>'da belirtilen amaçlar ve yöntemlerle mevzuatına uygun olarak kullanılacaktır.
            &nbsp;

          </CookieConsent>
        </div>
      </>
    );
  }
}
